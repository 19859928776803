import styled from 'styled-components';
import { font } from '../../atoms/typography';

export default styled.div`
  margin-top: 64px;

  .plans-wrapper {
    color: #fff;
    background: var(--dark-color);
    padding: 32px;
    border-radius: 12px;

    h2 {
      margin-bottom: 32px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: unset;
      text-transform: unset;
    }

    ul {
      padding: 0;

      li {
        list-style: none;
        ${font('text', 'sm', '400')}
        margin-bottom: 12px;
        color: #d8dae6;
        display: flex;
        gap: 0.5rem;
        align-items: start;

        h3 {
          display: inline;
          ${font('text', 'sm', '400')}
          margin-top: 0.0125rem;
        }

        svg {
          color: #01c9a1;
          flex: 0 0 18px;
          height: 18px;
          width: 18px;
          margin-top: 0.15rem;
        }
      }
    }
  }
`;
